// модуль инициализации fancybox

app.modules.fancyboxInit = (function (self) {
  function _init() {
    $.fancybox.defaults.i18n.ru = app.config.fancyboxSettings;
    $.fancybox.defaults.lang = 'ru';
    $('.js-fancybox').fancybox();
  }

  self.load = _init;
  return self;
})(app.modules.fancyboxInit || {});
