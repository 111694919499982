/**
 * Извлекает цену из данной HTML строки.
 *
 * Если преобразование успешно, она возвращает извлеченную цену.
 * Если такой элемент не найден или преобразование неудачно, она возвращает null.
 *
 * @param {string} htmlString - HTML строка, из которой нужно извлечь цену.
 * @returns {number|null} Извлеченная цена, или null, если цену извлечь не удалось.
 */

export const extractPrice = (htmlString) => {
  htmlString = htmlString.replace(/(\d)\s+(\d)/g, '$1$2').replace(/,/, '.');

  const div = document.createElement('div');
  div.innerHTML = htmlString;

  const toPriceElement = div.querySelector('[data-price-type="to"]');

  if (toPriceElement) {
    return preparePrice(toPriceElement);
  }

  const exactPriceElement = div.querySelector(
    '[data-price-type="exact"], [data-price-type="from"], [data-price-type="discount-new"]'
  );

  if (exactPriceElement) {
    return preparePrice(exactPriceElement);
  }

  return null;
};

const preparePrice = (priceElement) => {
  const price = parseFloat(priceElement.textContent.trim().replace(/\s/g, ''));
  if (!isNaN(price)) {
    return price;
  }
};
