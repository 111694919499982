// Модуль отвечающий за синхронизацию пользователя между вкладками

// По событию receivedUserData:crossDomainAuthSync перезагружаем страницу.
// Событие receivedUserData:crossDomainAuthSync является кроссдоменным и отправляется из
// модуля crossDomainAuthBridge после обновления токена в кроссдоменном localstorage.

// Модуль предоставляет метод app.modules.crossDomainAuthSync.updateUserData
// для случаев, когда авторизация происходит без перезагрузки страницы.
// Например после создания SID-пользователя.

app.modules.crossDomainAuthSync = (function (self) {
  const SIGNIN_URL = '/users/session/handle_signin';
  const SESSION_URL = '/users/session/request';
  const headers = new Headers({'x-requested-with': 'XMLHttpRequest'});

  function _updateUserData() {
    return fetch(SESSION_URL, {headers: headers})
      .then(function (res) {
        return res.json();
      })
      .then(function (res) {
        IStorage.set('ssoUserToken', res.token || '', function () {
          IStorage.trigger(
            'receivedUserData:crossDomainAuthSync',
            app.config.currentUser
          );
        });
      });
  }

  function _reloadPage() {
    location.reload();
  }

  // Функция вызывается в ответ на изменение
  // localstorage в других вкладках
  function _onLocalStorageChange(data) {
    if (
      app.config.disableSessionsSynchronization ||
      (app.config.currentUser.id === data.id &&
        app.config.currentUser.primaryProvider === data.primaryProvider)
    ) {
      return;
    }

    IStorage.get('ssoUserToken', function (storageToken) {
      const formData = new FormData();
      formData.append('token', storageToken);

      // Предварительно пытаемся авторизоваться на странице,
      // что бы после перезагрузки сразу же получить страницу
      // для авторизованного пользователя
      fetch(SIGNIN_URL, {method: 'POST', body: formData, headers: headers})
        .then(_reloadPage)
        .catch(function (error) {
          console.error(error);
          _reloadPage();
        });
    });
  }

  IStorage.on('receivedUserData:crossDomainAuthSync', _onLocalStorageChange);
  self.updateUserData = _updateUserData;
  self.load = function () {};
  return self;
})(app.modules.crossDomainAuthSync || {});
