// Загрузчик яндекс баннеров

import getOnScreenElements from '../utils/on_screen_checker.js';
import get from 'lodash.get';
import throttle from 'lodash.throttle';

app.modules.yandexDirectLoader = ((self = {}) => {
  const CLASSES = {
    toRender: 'js-yandex-direct-to-render',
  };

  const SELECTORS = {
    toRender: `.${CLASSES.toRender}`,
  };

  let _advManager;

  function _renderBanner(element) {
    if (!element) {
      return;
    }

    const id = element.id || 0;

    _advManager.render({
      blockId: id,
      renderTo: id,
    });

    element.classList.remove(CLASSES.toRender);
    element.classList.add('loaded');
  }

  function _renderOnScreenBanners() {
    const elements = getOnScreenElements(SELECTORS.toRender, 300);
    elements.some(_renderBanner);
  }

  function _listener() {
    document.addEventListener('scroll', throttle(_renderOnScreenBanners, 100));
    document.addEventListener(
      'render:yandexDirectLoader',
      _renderOnScreenBanners
    );
  }

  function _init() {
    _advManager = get(window.Ya, 'Context.AdvManager');

    if (!_advManager) {
      return;
    }

    _renderOnScreenBanners();
    _listener();
  }

  function _getLibrary() {
    const script = document.createElement('script');

    script.src = 'https://yandex.ru/ads/system/context.js';
    script.async = true;
    script.onload = _init;

    document.body.appendChild(script);
  }

  self.load = _getLibrary;

  return self;
})(app.modules.yandexDirectLoader);
