export function registerWithSID() {
  var $$regUser = $.Deferred();

  if (app.config.isRequestBot) {
    return $$regUser;
  }

  if (app.modules.authComponent) {
    $doc.trigger('register:authComponent', [$$regUser, {withSID: true}]);
  } else {
    apressImport(app.deferredPackageUrls.authComponent).then(() => {
      $doc.trigger('register:authComponent', [$$regUser, {withSID: true}]);
    });
  }

  return $$regUser;
}
