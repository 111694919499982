/**
 * Отправляет события в flocktory.
 *
 * @param {string} event - Название события.
 * @param {Object} [params={}] - Данные юнита по которому отправлено событие.
 */

export const sendFlocktoryEvent = (event, params = {}) => {
  window.flocktory = window.flocktory || [];
  window.flocktory.push([
    event,
    {
      item: params,
    },
  ]);
};
