var Handlebars = require("../../../../../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"js-bot-detected-popup\">\n  "
    + ((stack1 = container.lambda(depth0, depth0)) != null ? stack1 : "")
    + "\n  <button class=\"bot-detected-popup__close-button aui-button js-ga-link js-bot-detected-popup-close\" data-ga-id=\"PC4-23550-1\">\n    "
    + container.escapeExpression(__default(require("../../../../../../../../../../../node_modules/@apress/handlebars-helpers/helpers/i18n.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"apress_recaptcha.bot_detected_popup.close_button",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":4,"column":63}}}))
    + "\n  </button>\n</div>\n";
},"useData":true});