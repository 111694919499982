// Синхронизируем состояние кнопок при изменении в других вкладках

import {CHANGE_EVENT} from './constants';
import {toggleFavoriteButtons} from './utils';

function handleFavoriteProductChange({activate, productId, productsCount}) {
  vuexStore.commit('favoriteProducts/setCount', productsCount);
  toggleFavoriteButtons(activate, productId);
}

export default function enableTabsSync() {
  IStorage.on(CHANGE_EVENT, handleFavoriteProductChange);
}
