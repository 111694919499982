// Отмечаем все избранные товары на странице

import {API} from './constants';
import {toggleFavoriteButtons} from './utils';

export default function markFavoriteProducts() {
  fetch(API.get)
    .then((res) => res.json())
    .then((data) => {
      const productsList = data['favorite_products'];
      if (!productsList) {
        return;
      }

      vuexStore.commit('favoriteProducts/setCount', productsList.length);
      productsList.forEach(toggleFavoriteButtons.bind(null, true));

      if (app.config.isMobile) return;

      vuexStore.commit(
        'favoriteProducts/setProducts',
        data['favorite_products_objects']
      );
    });
}
