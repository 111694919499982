// Проверка пользователя с помощью smartCaptcha от Яндекс

app.modules.smartCaptcha = ((self) => {
  let _tokenPurpose;
  const SMART_CAPTCHA =
    'https://smartcaptcha.yandexcloud.net/captcha.js?render=onload&onload=onloadFunction';

  const _resetCaptcha = () => {
    window.smartCaptcha.reset();
    _tokenPurpose = '';
  };

  const _checkCaptcha = (tokenPurpose) => {
    _tokenPurpose = tokenPurpose;
    window.smartCaptcha.execute();
  };

  const _sendToken = (token) => {
    document.dispatchEvent(new CustomEvent(_tokenPurpose, {detail: token}));
  };

  const _addTotalCaptcha = () => {
    if (!window.smartCaptcha) return;

    if (app.config.yandexCaptchaTest) {
      window.smartCaptcha._test = true;
    }

    window.smartCaptcha.render('js-total-yandex-captcha-container', {
      sitekey: app.config.yandexCaptchaKey,
      hl: 'ru',
      invisible: true,
      hideShield: true,
      callback: _sendToken,
    });
  };

  const _addCaptchaScript = () => {
    if (!app.config.yandexCaptchaKey) return;

    const script = document.createElement('script');
    script.src = SMART_CAPTCHA;
    document.body.appendChild(script);

    script.onload = () => {
      document.addEventListener('addTotalCaptcha', _addTotalCaptcha, {
        once: true,
      });
      document.dispatchEvent(new Event('smartCapcha:loaded'));
    };
  };

  self = {
    reset: _resetCaptcha,
    check: _checkCaptcha,
    load: _addCaptchaScript,
  };

  return self;
})(app.modules.smartCaptcha || {});
