import dialogTemplate from './dialog.handlebars';
import {
  addAllPopupOpenedHistory,
  removeAllPopupOpenedHistory,
} from '@apress/apress-frontend-utils/utils/popup-opened-history/popup-opened-history';

app.modules.modalDialog = ((self = {}) => {
  function _create(initialOptions) {
    const dialog = {
      options: initialOptions,

      uuid: Math.random().toString(36).substring(8),

      open() {
        document.body.append(this.dialogEl);
        document.body.style.overflow = 'hidden';
      },

      destroy() {
        this.dialogEl.remove();
      },

      close() {
        this.options.close && this.options.close();
        this.destroy();
        document.body.style.overflow = '';
        app.config.isMobile && removeAllPopupOpenedHistory(this.uuid);
      },

      executeScripts() {
        this.dialogEl.querySelectorAll('script').forEach((executedScriptEl) => {
          const scriptEl = document.createElement('script');
          scriptEl.innerHTML = executedScriptEl.innerHTML;
          this.dialogEl.append(scriptEl);
        });
      },

      init() {
        app.config.isMobile &&
          addAllPopupOpenedHistory(this.close.bind(this), this.uuid);
        this.dialogEl = document.createElement('div');
        this.dialogEl.innerHTML = dialogTemplate({
          popupClass: this.options.dialogClass,
          contentClass: this.options.dialogContentClass,
          showHeader: this.options.title,
          overlayClass: this.options.overlayClass,
          hideCloseButton: this.options.hideCloseButton,
          showUiHeader: this.options.showUiHeader,
        });

        this.dialogEl = this.dialogEl.firstElementChild;
        this.dialogEl
          .querySelector('.js-modal-dialog__content')
          .append(this.options.content);
        this.options.title &&
          this.dialogEl
            .querySelector('.js-modal-dialog__title')
            .append(this.options.title);

        if (!this.options.hideCloseButton) {
          this.dialogEl
            .querySelector('.js-modal-dialog__close')
            .addEventListener('click', this.close.bind(this));
        }

        if (!this.options.noCloseOverlay) {
          this.dialogEl
            .querySelector('.js-modal-dialog__overlay')
            .addEventListener('click', this.close.bind(this));
        }

        this.open();
        this.executeScripts();
        this.options.open && this.options.open();
      },

      update(newOptions) {
        this.options = newOptions;
        this.destroy();
        this.init();
      },
    };

    dialog.init();
    return dialog;
  }

  self.create = _create;
  self.load = () => {};

  process.env.NODE_ENV === 'test' && (module.exports = self);
  return self;
})(app.modules.modalDialog);
