import _get from 'lodash.get';

export const SELECTORS = {
  button: '.js-favorite-button',
  counter: '.js-favorite-counter',
};

export const API = _get(app, 'config.api.favoriteProducts');

export const CHANGE_EVENT = 'change:favoriteProducts';
