/**
 * Модуль для управления избранными товарами.
 *
 * Кнопка для добавления/удаления товара из избранного - button.js-favorite-button
 * с дата атрибутами productId и isFavorite. При клике отправляется post/delete
 * запрос к АПИ и обновляется внешний вид кнопки.
 *
 * app.modules.favoriteProducts.markFavoriteProducts - отмечает все
 * избранные товары на странице (автоматически запускается после загрузки страницы).
 */

import {API} from './constants';
import markFavoriteProducts from './mark_favorite_products';
import addButtonsListener from './add_buttons_listener';
import enableTabsSync from './enable_tabs_sync';

app.modules.favoriteProducts = ((self = {}) => {
  function _checkVisitedProducts() {
    if (!document.querySelector('.js-visited-products-slider')) {
      return;
    }
    markFavoriteProducts();
    document.removeEventListener('scroll', _checkVisitedProducts);
  }

  function _addListener() {
    document.addEventListener('scroll', _checkVisitedProducts);
  }

  function _init() {
    if (!API) {
      return;
    }

    if (app.config.isUserSigned) {
      markFavoriteProducts();
      _listener();
    }
    _addListener();
    addButtonsListener();
    enableTabsSync();
  }

  function _listener() {
    document.addEventListener(
      'markFavorite:visitedProducts',
      markFavoriteProducts
    );
  }

  self.load = _init;
  self.update = markFavoriteProducts;
  return self;
})(app.modules.favoriteProducts);
