// Попап с информацией о непрочитанных комментариях и новых заказах при входе в систему

import Cookies from 'js-cookie';

app.modules.newOrdersInfoPopup = ((self = {}) => {
  const _popup = document.querySelector('.js-new-orders-info-popup');
  const _closeButton = document.querySelector(
    '.js-new-orders-info-popup-close'
  );

  function _show() {
    _popup.classList.remove('dn');
  }

  function _close() {
    _popup.remove();
    _setCookie();
  }

  function _setCookie() {
    Cookies.set('hide_new_orders_info_popup', '1', {
      expires: 1,
      path: '/',
      domain: ~location.host.indexOf(app.config.rootHost)
        ? app.config.rootHost
        : window.punycode.toASCII(location.host),
    });
  }

  function _setListeners() {
    _closeButton.addEventListener('click', _close);
  }

  function _init() {
    if (!_popup) {
      return;
    }

    _setListeners();
    _show();
  }

  self.load = _init;
  return self;
})(app.modules.newOrdersInfoPopup);
