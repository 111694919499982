// Модуль для обработки переходов по истории
// в качестве state ожидается объект со свойством type

app.modules.historyControl = ((self = {}) => {
  function _listener() {
    window.addEventListener('popstate', (event) => {
      const state = event.state;
      const type = state && state.type;

      if (!type) {
        return;
      }

      switch (type) {
        case 'reload':
          window.location.reload();
          break;

        default:
          break;
      }
    });
  }

  self.load = _listener;

  return self;
})(app.modules.historyControl);
