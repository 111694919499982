// Проверка пользователя с помощью reCAPTCHA

import botDetectedPopup from './templates/bot_detected_popup.handlebars';

app.modules.recaptcha = (function(self) {
  var _scriptIsLoaded;

  function _getToken(action, delay) {
    var
      $$token = $.Deferred();

    grecaptcha.ready(function() {
      setTimeout(function() {
        grecaptcha.execute(
          app.config.apressRecaptcha.siteKey,
          {action: action || app.config.apressRecaptcha.action}
        ).then(response => $$token.resolve(response));
      }, delay || 0);
    });

    return $$token;
  }

  function _getScript() {
    if (_scriptIsLoaded) { return $.Deferred().resolve(); }
    return $.getScript('https://www.google.com/recaptcha/api.js?render=' + app.config.apressRecaptcha.siteKey)
      .done(function() {
        _scriptIsLoaded = true;
        return $.Deferred().resolve();
      });
  }

  function _showBotDetectedPopup() {
    $doc.trigger('showBotDetectedPopup:recaptcha');

    return  $(botDetectedPopup(app.config.apressRecaptcha.botPopupTextZone)).dialog({
      modal: true,
      minHeight: 0,
      dialogClass: 'bot-detected-popup'
    });
  }

  function _sendOrderRequestWithToken(action, data, {url, method, contentType}) {
    _getScript()
      .then(() => _getToken(action))
      .then((token) => {
        data['response_token'] = token;

        return fetch(url, {
            headers: {
              'Content-Type': contentType
            },
            credentials: 'include',
            method,
            body: (contentType === 'application/json') ? JSON.stringify(data) : data
          })
          .then((res) => {
            if (!res.ok) {
              return Promise.reject(res.statusText);
            }
            return res.text();
          })
          .then((data) => {
            return data.length ? JSON.parse(data) : null;
          })
          .catch((error) => {
            console.error(error);
          })
      })
      .then(_sendUserBotGAEvent);
  }

  function _sendUserBotGAEvent({error}) {
    if (error && (error.message === 'bot request')) {
      window.dispatchEvent(new Event('send:googleAnalytics', 'ORDERS-2450'));
      document.dispatchEvent(new Event('userIsBot:recaptcha'));
    }
  }

  function _getVerifyResponse(token) {
    var
      $$verifyResponse = $.Deferred();

    $.ajax({type: 'get', url: app.config.apressRecaptcha.url, data: {'response_token': token}})
      .then(function(response) {
        return $$verifyResponse.resolve(response);
      });

    return $$verifyResponse;
  }

  function _verifyUser() {
    _getScript()
      .then(() => _getToken(app.config.apressRecaptcha.action, app.config.apressRecaptcha.delay))
      .then(token => _getVerifyResponse(token))
      .then(response => {
        if (response.score > app.config.apressRecaptcha.minScoreValue) {
          document.dispatchEvent(new Event('userIsNotBot:recaptcha'));
        }
      });
  }

  function _addListeners() {
    $doc
      .on('click', '.js-bot-detected-popup-close', function() {
        $('.js-bot-detected-popup').dialog('close');
      });
  }

  self.load = function() {
    _addListeners();
  };

  return $.extend(self, {
    getScript: _getScript,
    getToken: _getToken,
    showBotDetectedPopup: _showBotDetectedPopup,
    sendOrderRequestWithToken: _sendOrderRequestWithToken,
    verifyUser: _verifyUser
  });
})(app.modules.recaptcha || {});
