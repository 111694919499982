var Handlebars = require("../../../../../../../../static/blizko/local/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"modal-dialog__title-container\">\n      <button type=\"button\" class=\"modal-dialog__close-button aui-icon aui-icon_arrow-back aui-icon_arrow-back_color_gray js-modal-dialog__close\"></button>\n      <h2 class=\"modal-dialog__title modal-dialog__title_ui js-modal-dialog__title\"></h2>\n    </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "modal-dialog__popup_inside-indent";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"showHeader") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":11,"column":6},"end":{"line":18,"column":13}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"modal-dialog__header\">\n          <h2 class=\"modal-dialog__title js-modal-dialog__title\"></h2>\n          <div class=\"modal-dialog__close js-modal-dialog__close\"></div>\n        </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"modal-dialog__close js-modal-dialog__close\"></div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-dialog js-modal-dialog\">\n  <div class=\"modal-dialog__overlay js-modal-dialog__overlay "
    + alias4(((helper = (helper = lookupProperty(helpers,"overlayClass") || (depth0 != null ? lookupProperty(depth0,"overlayClass") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"overlayClass","hash":{},"data":data,"loc":{"start":{"line":2,"column":61},"end":{"line":2,"column":77}}}) : helper)))
    + "\"></div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showUiHeader") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":2},"end":{"line":8,"column":9}}})) != null ? stack1 : "")
    + "  <div class=\"modal-dialog__popup "
    + alias4(((helper = (helper = lookupProperty(helpers,"popupClass") || (depth0 != null ? lookupProperty(depth0,"popupClass") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"popupClass","hash":{},"data":data,"loc":{"start":{"line":9,"column":34},"end":{"line":9,"column":48}}}) : helper)))
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showUiHeader") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":49},"end":{"line":9,"column":109}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"showUiHeader") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":4},"end":{"line":19,"column":15}}})) != null ? stack1 : "")
    + "    <div class=\"modal-dialog__content js-modal-dialog__content "
    + alias4(((helper = (helper = lookupProperty(helpers,"contentClass") || (depth0 != null ? lookupProperty(depth0,"contentClass") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"contentClass","hash":{},"data":data,"loc":{"start":{"line":20,"column":63},"end":{"line":20,"column":79}}}) : helper)))
    + "\"></div>\n  </div>\n</div>\n";
},"useData":true});