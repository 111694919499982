// Запись статистики переходов по акциям

app.modules.externalOfferLinkLog = ((self = {}) => {
  function _listener() {
    document.addEventListener('click', (event) => {
      const link = event.target.closest('.js-external-offer-link-log');
      if (!link) {
        return;
      }
      fetch(link.dataset['offerleadsurl'], {method: 'POST'});
    });
  }

  self.load = _listener;
  return self;
})(app.modules.externalOfferLinkLog);
