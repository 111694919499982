// TODO: этот модуль не имеет отношения к авторизации, его нужно вынести отсюда

// Модуль для установки опции withCredentials в зависимости
// от настроек аякс запроса (crossDomain: bool, declineCredentials: bool).
// Используется для решения проблемы https://jira.railsc.ru/browse/PC4-22759

app.modules.crossDomainAuthCors = (function (self) {
  function _onAjaxSend(event, request, settings) {
    if (!settings.crossDomain) {
      return;
    }
    settings.xhrFields = {
      withCredentials: settings.declineCredentials ? false : true,
    };
    request.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
  }

  function _init() {
    $(document).ajaxSend(_onAjaxSend);
  }

  self.load = _init;
  return self;
})(app.modules.crossDomainAuthCors || {});
