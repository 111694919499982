import {SELECTORS} from './constants';

export function toggleFavoriteButtons(activate, productId) {
  const buttons = document.querySelectorAll(
    `${SELECTORS.button}[data-product-id="${productId}"]`
  );

  buttons.forEach((buttonEl) => {
    buttonEl.classList.toggle('active', activate);
    buttonEl.classList.toggle('js-conversion-event', !activate);
    buttonEl.dataset.isFavorite = activate;
    if (buttonEl.title)
      buttonEl.title = activate
        ? app.locales.removeFromFavorite
        : app.locales.addToFavorite;
    if (buttonEl.innerText)
      buttonEl.innerText = activate
        ? app.locales.inFavorite
        : app.locales.toFavorite;
  });
}

export function toggleFavoritePageLinkUrl(productsCount) {
  const favoritePageLink = document.querySelector('.js-favorite-page-link');

  if (!favoritePageLink) return;

  const {favoriteProductsUrl, favoriteCompaniesUrl} = favoritePageLink.dataset;
  favoritePageLink.href = productsCount
    ? favoriteProductsUrl
    : favoriteCompaniesUrl;
}
