// Модуль кросдоменной авторизации

// Читаем токен из IStorage, если он есть пытаемся авторизоваться.
// При успехе записываем данные пользователя в конфиг.
// При успехе/провале/отсутствии_токена запрашиваем и сохраняем новый токен.
// Алгоритм одинаковый для всех страниц (портал, домены 2 и 3 уровня).

// После того как все готово резолвим app.config.authIsComplete,
// что бы дать понять приложению что авторизация закончена.

app.modules.crossDomainAuthBridge = (function (self) {
  const SIGNIN_URL = '/users/session/handle_signin';
  const SESSION_URL = '/users/session/request';

  const headers = new Headers({'x-requested-with': 'XMLHttpRequest'});
  const toJson = function (res) {
    return res.json();
  };
  app.config.authIsComplete = Deferred();

  function Deferred() {
    var res,
      rej,
      p = new Promise(function (a, b) {
        res = a;
        rej = b;
      });
    p.resolve = res;
    p.reject = rej;
    return p;
  }

  function _toTryAuth(storageToken) {
    const formData = new FormData();
    formData.append('token', storageToken);

    return (
      storageToken &&
      fetch(SIGNIN_URL, {
        method: 'POST',
        body: formData,
        headers: headers,
      }).then(toJson)
    );
  }

  function _setUserData(res) {
    const user = res && res.user;

    if (user) {
      app.config.isUserSigned = !!user.id;
    }

    if (user && user.id !== app.config.currentUser.id) {
      user.primaryProvider = user['primary_provider'];
      app.config.currentUser = user;
    }
  }

  function _getToken() {
    return fetch(SESSION_URL, {headers: headers}).then(toJson);
  }

  // После авторизации на домене второго уровня
  // заранее авторизуем пользователя на портале.
  // Для решения проблемы при переходе
  // с СК на страницу /dashboard
  function _portalPreauth(res) {
    if (
      app.config.crossDomainAuth &&
      res.token &&
      !document.querySelector('.js-cross-domain-auth-bridge')
    ) {
      const iframe = document.createElement('iframe');
      iframe.src = app.config.crossDomainIframe;
      iframe.className = 'cross-domain-auth-bridge js-cross-domain-auth-bridge';
      iframe.onload = function () {
        iframe.contentWindow.postMessage(
          {action: 'preauth', token: res.token},
          app.config.crossDomainIframe
        );
      };
      document.body.append(iframe);
    }

    return res;
  }

  function _updateTokenInStorage(res) {
    IStorage.set('ssoUserToken', res.token || '', function () {
      IStorage.trigger(
        'receivedUserData:crossDomainAuthSync',
        app.config.currentUser
      );
    });
  }

  function _finishAuth() {
    app.config.authIsComplete.resolve();
  }

  IStorage.get('ssoUserToken', function (storageToken) {
    Promise.resolve(storageToken)
      .then(_toTryAuth)
      .then(_setUserData)
      .then(_getToken)
      .then(_portalPreauth)
      .then(_updateTokenInStorage)
      .then(_finishAuth)
      .catch(function (error) {
        console.error(error);
        _finishAuth();
      });
  });

  self.load = function () {};
  return self;
})(app.modules.crossDomainAuthBridge || {});
