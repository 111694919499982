// Функция для проверки видимости элементов на экране
// f('.selector', 200) -> [HTMLElement, ...]

let indent;

const isElementAbove = (element) =>
  element.getBoundingClientRect().top < window.innerHeight + indent;

const isElementBelow = (element) =>
  element.getBoundingClientRect().bottom > -indent;

const elementOnScreen = (element) =>
  isElementAbove(element) && isElementBelow(element);

export default (selector, threshold = 0) => {
  if (!selector) {
    return;
  }
  indent = threshold;
  const elements = document.querySelectorAll(selector);
  return [].filter.call(elements, elementOnScreen);
};
