// Модуль открытия fancybox попапов
import {
  addPopupOpenedHistory,
  removePopupOpenedHistory,
} from '@apress/apress-frontend-utils/utils/popup-opened-history/popup-opened-history.js';

app.modules.fancyboxPopup = (function (self) {
  function _openPopup(event) {
    const $this = $(this);
    const targetItemEl = event.originalEvent.target.closest('.js-swiper-item');

    if (targetItemEl) {
      $(targetItemEl.closest('.js-swiper-container')).data(
        'index',
        targetItemEl.dataset.index
      );
    }

    const index = $this[0].dataset.index ?? $this.data('index');

    $.fancybox.open(
      $this.data('images'),
      Object.assign(
        {
          loop: true,
          buttons: ['close'],
          index,
          thumbs: {
            autoStart: true,
            axis: 'x',
          },
          hash: false,
        },
        app.config.isMobile
          ? {
              afterShow: () => addPopupOpenedHistory(_closePopup),
              afterClose: () => removePopupOpenedHistory(_closePopup),
            }
          : {}
      )
    );
    event && event.preventDefault();
  }

  function _closePopup() {
    $.fancybox.close();
  }

  function _listener() {
    $doc.on('click', '.js-zoom-image', _openPopup);
  }

  self.load = function () {
    _listener();
  };

  return self;
})(app.modules.fancyboxPopup || {});
