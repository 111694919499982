// Модуль отправки запросов для подсчета статистики

app.modules.activitiesStatistic = ((self = {}) => {
  function _handleClick(event) {
    const element = event.target.closest('.js-activities-statistic');
    if (!element || !element.dataset.statisticUrl) {
      return;
    }

    fetch(element.dataset.statisticUrl, {method: 'POST'});
  }

  function _listeners() {
    document.addEventListener('click', _handleClick);
  }

  self.load = _listeners;
  return self;
})(app.modules.activitiesStatistic);
