// Модуль отправки запросов для рассылки "первое касание к компании".
// Для отправки просмотра страницы требуется id компании - app.config.companyTouch.companyId
// Для отправки кликов требуется записать true в объект - app.config.companyTouch.enableClicks

// Для удобства созданы паршалы с конфигами:
// enable_show_event -  включает отправку просмотров данной страницы
// enable_clicks_event - включает отправку кликов с данной страницы

// Сейчас отправка событий кликов предусмотрена только для элемента
// js-show-phone-number. Если понадобятся другие элементы, их список нужно
// будет вынести в конфиг app.config.companyTouch.clickElementClasses
// и передавать в паршал enable_clicks_event.

import get from 'lodash.get';

app.modules.companyTouch = ((self = {}) => {
  function _request(body) {
    fetch(app.config.api.companiesUserViewsUrl, {
      method: 'POST',
      body,
      headers: {'Content-Type': 'application/json;charset=utf-8'},
    });
  }

  function _sendViewEvent() {
    if (!get(app, 'config.companyTouch.companyId')) {
      return;
    }

    const body = JSON.stringify({
      company_id: app.config.companyTouch.companyId,
    });
    _request(body);
  }

  function _handleClick(event) {
    const element = event.target.closest('.js-show-phone-number');
    if (
      !element ||
      !element.dataset.companyId ||
      !get(app, 'config.companyTouch.enableClicks')
    ) {
      return;
    }

    const body = JSON.stringify({company_id: element.dataset.companyId});
    _request(body);
  }

  function _listeners() {
    document.addEventListener('click', _handleClick);
  }

  function _init() {
    if (!get(app, 'config.currentUser.email')) {
      return;
    }

    _listeners();
    _sendViewEvent();
  }

  self.load = _init;
  return self;
})(app.modules.companyTouch);
