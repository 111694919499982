// Обрабатываем клики по кнопкам "Избранное", отправляем запросы, переключаем кнопки

import {SELECTORS, API, CHANGE_EVENT} from './constants.js';
import {toggleFavoriteButtons, toggleFavoritePageLinkUrl} from './utils.js';
import {registerWithSID} from 'src/assets/js/utils/register_with_sid.js';
import {extractPrice} from 'src/assets/js/utils/extrat-price/extract-price.js';
import {sendFlocktoryEvent} from 'src/assets/js/utils/send-flocktory-event/send-flocktory-event.js';

function changeFavoriteProduct(favoriteButtonEl) {
  const productId = favoriteButtonEl.dataset.productId;
  const isAddHit = favoriteButtonEl.dataset.hitVisitsStatistics;
  const wasFavorite = favoriteButtonEl.dataset.isFavorite === 'true';
  const url =
    isAddHit && !wasFavorite
      ? `${API.set.replace(
          '-product_id-',
          productId
        )}&hit_visits_statistics=true`
      : API.set.replace('-product_id-', productId);

  fetch(url, {method: wasFavorite ? 'DELETE' : 'POST'})
    .then((res) => res.json())
    .then((body) => {
      const productsCount = body['favorite_products_count'];

      toggleFavoriteButtons(!wasFavorite, productId);
      toggleFavoritePageLinkUrl(productsCount);
      vuexStore.commit('favoriteProducts/setCount', productsCount);
      IStorage.trigger(CHANGE_EVENT, {
        productId,
        activate: !wasFavorite,
        productsCount,
      });
      flocktoryHandler(body['html_price'], productId);

      if (app.config.isMobile) return;

      wasFavorite ? removeProduct(productId) : addProduct(body);
    });
}

function flocktoryHandler(htmlProductPrice, productId) {
  if (!htmlProductPrice) {
    sendFlocktoryEvent('removeFromFavorites', {id: productId});
  } else {
    const price = extractPrice(htmlProductPrice);

    if (price) {
      sendFlocktoryEvent('addToFavorites', {id: productId, price});
    }
  }
}

function addProduct(product) {
  vuexStore.commit('favoriteProducts/addProduct', {
    name: product.name,
    image: product.image,
    html_price: product['html_price'],
    id: product['product_id'],
  });
}

function removeProduct(productId) {
  vuexStore.commit('favoriteProducts/removeProduct', productId);
}

function handleFavoriteButtonClick(event) {
  const favoriteButtonEl = event.target.closest(SELECTORS.button);
  if (!favoriteButtonEl) {
    return;
  }
  event.preventDefault();

  app.config.isUserSigned
    ? changeFavoriteProduct(favoriteButtonEl)
    : registerWithSID().then(
        changeFavoriteProduct.bind(null, favoriteButtonEl)
      );
}

export default function addButtonsListener() {
  document.addEventListener('click', handleFavoriteButtonClick);
}
