// Модуль для работы со скрытыми ссылками
//
// Пример html элемента:
// <span class='js-remote-link' data-params={query: {}}>Ссылка</span>
//
// data-params - json с параметрами:
//   query - данные для запроса ссылки (см. документацию apress-application)
//   target - аналог атрибута target для тега <a>

import get from 'lodash.get';

app.modules.remoteLinkLoader = ((self = {}) => {
  const CLASSES = {
    link: 'js-remote-link',
  };

  const SELECTORS = {
    link: `.${CLASSES.link}`,
  };

  const REMOTE_LINK_API = get(app, 'config.api.remoteLink');
  let linksList = {};

  function _followUrl(linkEl, url) {
    const target = linkEl.getAttribute('target');

    if (target === '_blank') {
      const newWindow = window.open(url);

      if (newWindow) {
        newWindow.focus();
      } else {
        window.location = url;
      }
    } else {
      window.location = url;
    }
  }

  function _loadUrl(link) {
    const query = JSON.stringify(JSON.parse(link.dataset.params).query);
    const url = `${REMOTE_LINK_API}?link_options=${query}`;
    const cachedLink = linksList[query];

    if (cachedLink) {
      return Promise.resolve(cachedLink);
    }

    return fetch(url)
      .then((res) => res.json())
      .then((data) => data.link);
  }

  function _preloadUrls() {
    const links = document.querySelectorAll(SELECTORS.link);
    const params = [].map.call(
      links,
      (linkEl) => JSON.parse(linkEl.dataset.params).query
    );

    fetch(REMOTE_LINK_API, {method: 'POST', body: JSON.stringify(params)})
      .then((res) => res.json())
      .then((data) => {
        linksList = data;
      });
  }

  function _handleClick(event) {
    const target = event.target;

    if (localStorage.getItem('isFacetsAjaxLoaded')) {
      localStorage.removeItem('isFacetsAjaxLoaded');
      return;
    }

    const linkEl = target.closest(SELECTORS.link);
    if (!linkEl || linkEl.classList.contains('js-os-disabled-link')) {
      return;
    }

    _loadUrl(linkEl).then((url) => {
      url && _followUrl(linkEl, url);
    });
  }

  function _listeners() {
    document.addEventListener('click', _handleClick);
  }

  function _init() {
    if (!REMOTE_LINK_API) {
      return;
    }
    setTimeout(_preloadUrls, 6000);
    _listeners();
  }

  self.load = _init;
  return self;
})(app.modules.remoteLinkLoader);
